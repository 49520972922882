<style scoped lang="scss">
  .system_config {
    .el-form {
      width: 60%;
      min-width: 250px;
    }
  }
</style>

<template>
  <div class="system_config">
    <el-form :model="configData" :rules="formRules" :ref="formRefName" label-width="100px">
      <el-form-item label="项目名称" prop="systemName">
        <el-input placeholder="请输入标题" v-model="configData.systemName"></el-input>
      </el-form-item>
      <el-form-item label="页码" prop="pageSize">
        <el-input placeholder="请输入页码" v-model="configData.pageSize"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" @click="updateConfig()">保存全部配置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import * as commons from '@/api/common'
  export default {
    name: "sysConfig",
    data() {
      return {
        configData: {},
        formRefName: "formRefName",
        formRules: {
          systemName: [{ required: true, message: '请输入标题', trigger: 'blur' }],
          pageSize: [{ required: true, message: '请输入页码', trigger: 'blur' }]
        }
      }
    },
    mounted() {
      this.getConfigData()
    },
    methods: {
      getConfigData() {
        window.$common.fullLoading()
        commons.getSystemSettings().then(res => {
          window.$common.closeFullLoading()
          this.configData = { ...res }
        })
      },
      updateConfig() {
        this.$refs[this.formRefName].validate(valid => {
          if (valid) {
            window.$common.fullLoading()
            commons.updateSystemSettings(this.configData).then(() => {
              this.alert("操作成功!").then(() => {
                this.showDialog = false
                window.$common.closeFullLoading()
                // 修改后重新保存storege
                this.setSystemConfig()
                // 刷新
                //location.reload()
              })
            })
          }
        })
      }
    }
  }
</script>